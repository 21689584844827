import { Switch, Route, Redirect } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import AppLocale from './lang'
import renderRoutes from './router/renderRoutes'
import Layout from './pages/Layout'
import {
  adminRoutes,
  authRoutes,
  authRoutesWithProps,
  publicRoutes,
  redirects
} from './router/RouteConfiguration'
import NotFound from './pages/NotFound'
import PublicLayout from './pages/Layout/publicLayout'
import { useTokenAuthentication } from './hooks/useTokenAuthentication'
import ReSigninModal from './pages/Auth/Login/ReSigningModal'

function Router(props) {
  const currentAppLocale = AppLocale[props.locale]
  const { isAuthenticated, user } = useSelector((state) => state.user)

  const { authModal, handleLoginRedirect, handleCloseModal } =
    useTokenAuthentication(isAuthenticated)

  return (
    <>
      {authModal && (
        <ReSigninModal
          show={authModal}
          onHide={handleCloseModal}
          onLogin={handleLoginRedirect}
        />
      )}
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
        onError={() => ''}
      >
        {isAuthenticated ? (
          <Layout>
            <Switch>
              {renderRoutes(authRoutes)}
              {user?.isAdmin && renderRoutes(adminRoutes)}
              {renderRoutes(authRoutesWithProps)}
              {redirects.map((redirect) => (
                <Redirect
                  key={redirect.from}
                  from={redirect.from}
                  to={redirect.to}
                  exact={redirect.exact || false}
                />
              ))}
              <Route component={NotFound} />
            </Switch>
          </Layout>
        ) : (
          <PublicLayout>
            <Switch>
              {renderRoutes(publicRoutes)}
              {redirects.map((redirect) => (
                <Redirect
                  key={redirect.from}
                  from={redirect.from}
                  to={redirect.to}
                  exact={redirect.exact || false}
                />
              ))}
              <Route component={NotFound} />
            </Switch>
          </PublicLayout>
        )}
      </IntlProvider>
    </>
  )
}

const mapStateToProps = ({ lang }) => {
  const { locale } = lang
  return { locale }
}

export default connect(mapStateToProps, {})(Router)
