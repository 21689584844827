import React, { useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../utils/canvasUtils'
import { useSelector, useDispatch } from 'react-redux'
import { setImageCropperData, setCroppedImage } from '../../redux'

const ImageCropper = (props) => {
  const general = useSelector((state) => state.general)
  const dispatch = useDispatch()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  // eslint-disable-next-line
  const [cropSize, setCropSize] = useState({
    width: props.width,
    height: props.height
  })
  const [zoom, setZoom] = useState(1)
  // eslint-disable-next-line
  const [rotation, setRotation] = useState(0)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  // eslint-disable-next-line
  useEffect(async () => {
    if (croppedAreaPixels) {
      const image = await getCroppedImg(
        general.imageCropperData,
        croppedAreaPixels,
        rotation
      )

      dispatch(setCroppedImage(image))
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels])

  useEffect(() => {
    return () => {
      props.setImageUrl('')
      dispatch(setCroppedImage(null))
      dispatch(setImageCropperData(null))
    }
    // eslint-disable-next-line
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  return (
    <div>
      <Cropper
        image={props.imageUrl}
        crop={crop}
        cropSize={cropSize}
        zoom={zoom}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        style={{ height: props.height ? props.height : '200px' }}
      />
    </div>
  )
}

export default ImageCropper
