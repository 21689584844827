export const FETCH_WATCHED_MASTERCLASS_VIDEOS_FULFILLED =
  'FETCH_WATCHED_MASTERCLASS_VIDEOS_FULFILLED'
export const FETCH_WATCHED_MASTERCLASS_VIDEOS_REJECTED =
  'FETCH_WATCHED_MASTERCLASS_VIDEOS_REJECTED'
export const FETCH_WATCHED_MASTERCLASS_VIDEOS_PENDING =
  'FETCH_WATCHED_MASTERCLASS_VIDEOS_PENDING'
export const FETCH_WATCHED_PODCAST_VIDEOS_FULFILLED =
  'FETCH_WATCHED_PODCAST_VIDEOS_FULFILLED'
export const FETCH_WATCHED_PODCAST_VIDEOS_REJECTED =
  'FETCH_WATCHED_PODCAST_VIDEOS_REJECTED'
export const FETCH_WATCHED_PODCAST_VIDEOS_PENDING =
  'FETCH_WATCHED_PODCAST_VIDEOS_PENDING'
export const CREATE_WATCHED_MASTERCLASS_PENDING =
  'CREATE_WATCHED_MASTERCLASS_PENDING'
export const CREATE_WATCHED_MASTERCLASS_FULFILLED =
  'CREATE_WATCHED_MASTERCLASS_FULFILLED'
export const CREATE_WATCHED_MASTERCLASS_REJECTED =
  'CREATE_WATCHED_MASTERCLASS_REJECTED'
export const CREATE_WATCHED_PODCAST_REJECTED = 'CREATE_WATCHED_PODCAST_REJECTED'
export const CREATE_WATCHED_PODCAST_FULFILLED =
  'CREATE_WATCHED_PODCAST_REJECTED'
export const CREATE_WATCHED_PODCAST_PENDING = 'CREATE_WATCHED_PODCAST_PENDING'
export const FETCH_PROFFICIENT_IAMR_SKILL_PENDING =
  'FETCH_PROFFICIENT_IAMR_SKILL_PENDING'
export const FETCH_PROFFICIENT_IAMR_SKILL_FULFILLED =
  'FETCH_PROFFICIENT_IAMR_SKILL_FULFILLED'
export const FETCH_PROFFICIENT_IAMR_SKILL_REJECTED =
  'FETCH_PROFFICIENT_IAMR_SKILL_REJECTED'
export const FETCH_COMPLETED_JOURNALS_PENDING =
  'FETCH_COMPLETED_JOURNALS_PENDING'
export const FETCH_COMPLETED_JOURNALS_FULFILLED =
  'FETCH_COMPLETED_JOURNALS_FULFILLED'
export const FETCH_COMPLETED_JOURNALS_REJECTED =
  'FETCH_COMPLETED_JOURNALS_REJECTED'
export const FETCH_COMPLETED_SPRINTS_PENDING = 'FETCH_COMPLETED_SPRINTS_PENDING'
export const FETCH_COMPLETED_SPRINTS_FULFILLED =
  'FETCH_COMPLETED_SPRINTS_FULFILLED'
export const FETCH_COMPLETED_SPRINTS_REJECTED =
  'FETCH_COMPLETED_SPRINTS_REJECTED'
export const FETCH_COMPLETED_FEEDBACKS_PENDING =
  'FETCH_COMPLETED_FEEDBACKS_PENDING'
export const FETCH_COMPLETED_FEEDBACKS_FULFILLED =
  'FETCH_COMPLETED_FEEDBACKS_FULFILLED'
export const FETCH_COMPLETED_FEEDBACKS_REJECTED =
  'FETCH_COMPLETED_FEEDBACKS_REJECTED'
export const FETCH_COMPLETED_MENTOR_MEETING_PENDING =
  'FETCH_COMPLETED_MENTOR_MEETING_PENDING'
export const FETCH_COMPLETED_MENTOR_MEETING_FULFILLED =
  'FETCH_COMPLETED_MENTOR_MEETING_FULFILLED'
export const FETCH_COMPLETED_MENTOR_MEETING_REJECTED =
  'FETCH_COMPLETED_MENTOR_MEETING_REJECTED'
export const FETCH_COMPLETED_PORTFOLIO_CONTENT_PENDING =
  'FETCH_COMPLETED_PORTFOLIO_CONTENT_PENDING'
export const FETCH_COMPLETED_PORTFOLIO_CONTENT_FULFILLED =
  'FETCH_COMPLETED_PORTFOLIO_CONTENT_FULFILLED'
export const FETCH_COMPLETED_PORTFOLIO_CONTENT_REJECTED =
  'FETCH_COMPLETED_PORTFOLIO_CONTENT_REJECTED'
