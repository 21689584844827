//loading
export const LOADING = 'LOADING'

// login
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const LOGIN_LOADING = 'LOGIN_LOADING'
export const NEED_RESET = 'NEED_RESET'

// logout
export const USER_LOGOUT = 'USER_LOGOUT'

//update user
export const USER_CHANGE_NAME = 'USER_CHANGE_NAME'
export const USER_CHANGE_PROFILE_IMAGE = 'USER_CHANGE_PROFILE_IMAGE'
export const USER_EDIT_ERROR = 'USER_EDIT_ERROR'
export const EDIT_SOCIAL_MEDIA = 'EDIT_SOCIAL_MEDIA'

// remove error message
export const USER_REMOVE_ERROR_MESSAGE = 'USER_REMOVE_ERROR_MESSAGE'

// update user term and condition
export const UPDATE_USER_TNC = 'UPDATE_USER_TNC'
