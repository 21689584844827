export const FETCH_STEP_PENDING = 'FETCH_STEP_PENDING'
export const FETCH_STEP_FULFILLED = 'FETCH_STEP_FULFILLED'
export const FETCH_STEP_REJECTED = 'FETCH_STEP_REJECTED'

export const FETCH_ALL_INDUSTRIES_PENDING = 'FETCH_ALL_INDUSTRIES_PENDING'
export const FETCH_ALL_INDUSTRIES_FULFILLED = 'FETCH_ALL_INDUSTRIES_FULFILLED'
export const FETCH_ALL_INDUSTRIES_REJECTED = 'FETCH_ALL_INDUSTRIES_REJECTED'

export const FETCH_ALL_COMPANIES_PENDING = 'FETCH_ALL_COMPANIES_PENDING'
export const FETCH_ALL_COMPANIES_FULFILLED = 'FETCH_ALL_COMPANIES_FULFILLED'
export const FETCH_ALL_COMPANIES_REJECTED = 'FETCH_ALL_COMPANIES_REJECTED'

export const FETCH_ALL_INDUSTRY_PROBLEMS_PENDING =
  'FETCH_ALL_INDUSTRY_PROBLEMS_PENDING'
export const FETCH_ALL_INDUSTRY_PROBLEMS_FULFILLED =
  'FETCH_ALL_INDUSTRY_PROBLEMS_FULFILLED'
export const FETCH_ALL_INDUSTRY_PROBLEMS_REJECTED =
  'FETCH_ALL_INDUSTRY_PROBLEMS_REJECTED'

export const FETCH_ALL_EXPERIENCES_PENDING = 'FETCH_ALL_EXPERIENCES_PENDING'
export const FETCH_ALL_EXPERIENCES_FULFILLED = 'FETCH_ALL_EXPERIENCES_FULFILLED'
export const FETCH_ALL_EXPERIENCES_REJECTED = 'FETCH_ALL_EXPERIENCES_REJECTED'

export const CREATE_INDUSTRY_PROBLEM_PENDING = 'CREATE_INDUSTRY_PROBLEM_PENDING'
export const CREATE_INDUSTRY_PROBLEM_FULFILLED =
  'CREATE_INDUSTRY_PROBLEM_FULFILLED'
export const CREATE_INDUSTRY_PROBLEM_REJECTED =
  'CREATE_INDUSTRY_PROBLEM_REJECTED'

export const CREATE_IMMERSION_EXPERIENCE_PENDING =
  'CREATE_IMMERSION_EXPERIENCE_PENDING'
export const CREATE_IMMERSION_EXPERIENCE_FULFILLED =
  'CREATE_IMMERSION_EXPERIENCE_FULFILLED'
export const CREATE_IMMERSION_EXPERIENCE_REJECTED =
  'CREATE_IMMERSION_EXPERIENCE_REJECTED'
