export const GET_BRIEFINGS_START = 'GET_BRIEFINGS_START'
export const GET_BRIEFINGS_SUCCESS = 'GET_BRIEFINGS_SUCCESS'
export const GET_BRIEFINGS_ERROR = 'GET_BRIEFINGS_ERROR'

export const POST_BRIEFING_START = 'POST_BRIEFING_START'
export const POST_BRIEFING_SUCCESS = 'POST_BRIEFING_SUCCESS'
export const POST_BRIEFING_ERROR = 'POST_BRIEFING_ERROR'
export const EDIT_BRIEFING_START = 'EDIT_BRIEFING_START'
export const EDIT_BRIEFING_SUCCESS = 'EDIT_BRIEFING_SUCCESS'
export const EDIT_BRIEFING_ERROR = 'EDIT_BRIEFING_ERROR'

export const DELETE_BRIEFING_START = 'DELETE_BRIEFING_START'
export const DELETE_BRIEFING_SUCCESS = 'DELETE_BRIEFING_SUCCESS'
export const DELETE_BRIEFING_ERROR = 'DELETE_BRIEFING_ERROR'
