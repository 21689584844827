import React, { useEffect, useState } from 'react'
import JournalTextEditor from '../../../components/JournalTextEditor/JournalTextEditor'

function InstructorFeedback(props) {
  const [instructorFeedback, setInstructorFeedback] = useState(null)

  const handleChangeInstructorFeedback = (data) => {
    console.log('data', data)
  }

  const handleSave = (updatedData) => {
    console.log('updatedData', { ...updatedData })
  }

  useEffect(() => {
    if (props.data) setInstructorFeedback(props.data)
  }, [props.data])

  return (
    <JournalTextEditor
      userData={instructorFeedback?.userInstructorFeedback}
      value={instructorFeedback?.userInstructorFeedback?.content}
      handleChange={handleChangeInstructorFeedback}
      handleSave={(data) => {
        handleSave(data)
      }}
      title={'Instructor feedback'}
      previewMode={'on'}
    />
  )
}

export default InstructorFeedback
