import React from 'react'
import { NavLink } from 'react-router-dom'
import SUSLogoStudent from '../../assets/images/LTS-logo-horizontal.png'
import ltsHSLogo from '../../assets/images/HS-Sidebar/Market-READY label.png'
import { useSelector } from 'react-redux'

const Header = (props) => {
  const studentLevel = useSelector((state) => state.user.user?.user?.level)
  return (
    <div className='sidebar-header' onClick={() => props.hideHeaderIcons?.()}>
      <NavLink to='/dashboard'>
        <img src={SUSLogoStudent} alt='logo' />
      </NavLink>
      {studentLevel !== 'HS' ? (
        <div
          style={{
            textTransform: 'uppercase',
            color: '#707070',
            fontSize: 15
          }}
        >
          Learner platform
        </div>
      ) : (
        <img src={ltsHSLogo} alt='logo' />
      )}
    </div>
  )
}

export default Header
