import React from 'react'
import CSVUpload from '../components/CSVUpload'
import LtsJournal from '../pages/LtsJournal'
import AddOccupationItemForm from '../components/Pathways/CreateOccupation'
import MyCourseEntrepreneurship from '../pages/MyCourseEntrepreneurship'
import VerifyEmailByCode from '../pages/Register/verifyEmailByCode'

const Login = React.lazy(() => import('../pages/Auth/Login/HS-index'))
const Terms = React.lazy(() => import('../pages/Terms'))
const Register = React.lazy(() => import('../pages/Register'))
const Dashboard = React.lazy(() => import('../pages/Dashboard'))
const Portfolio = React.lazy(() => import('../pages/Portfolio'))
const SavedMedia = React.lazy(() => import('../pages/Saved'))
const Resubscribe = React.lazy(() => import('../pages/Resubscribe'))
const StartupLive = React.lazy(() => import('../pages/StartupLive'))
const Spotlight = React.lazy(() => import('../pages/Spotlight'))
const MySpark = React.lazy(() => import('../pages/MySpark'))
const IAMR = React.lazy(() => import('../pages/Iamr'))
const MyNotes = React.lazy(() => import('../pages/MyNotes'))
const SampleNote = React.lazy(() => import('../pages/MyNotes/sampleNote'))
const MyJournals = React.lazy(() => import('../pages/MyJournals'))
const Logout = React.lazy(() => import('../pages/Auth/LogOut'))
const VerifyEmail = React.lazy(() => import('../pages/Verify'))
const MyClassroom = React.lazy(() => import('../pages/MyClassroom'))
const StoryInMotion = React.lazy(() => import('../pages/StoryInMotion'))
const MyImmersion = React.lazy(() => import('../pages/MyImmersion'))
const Steps = React.lazy(() => import('../pages/MyImmersion/Steps'))
const Pathways = React.lazy(() => import('../pages/Pathways'))
const Profile = React.lazy(() => import('../pages/Profile/index'))
const SecurePage = React.lazy(() => import('../pages/Secure'))
const LiveStream = React.lazy(() => import('../pages/StartupLive/livestream'))
const AllVideos = React.lazy(() =>
  import('../pages/BeyondYourCourse/allVideos')
)
const BeyondYourCourse = React.lazy(() => import('../pages/BeyondYourCourse'))

const ChooseLogin = React.lazy(() =>
  import('../pages/Auth/Login/ChooseLogin/ChooseLogin')
)
const ForgotPassword = React.lazy(() =>
  import('../pages/Auth/Login/forgotPassword')
)
const ResetPassword = React.lazy(() =>
  import('../pages/Auth/Login/resetPassword')
)
const CreateAccount = React.lazy(() =>
  import('../pages/Auth/Login/createAccount')
)
const BeyondYourCourseVideo = React.lazy(() =>
  import('../pages/BeyondYourCourse/beyondYourCourseVideo')
)
const ProfilePreview = React.lazy(() =>
  import('../pages/Profile/profilePreview')
)
const MyMarketReadyGuide = React.lazy(() =>
  import('../pages/MyMarketReadyGuide')
)
const PreviewPublicPortfolio = React.lazy(() =>
  import('../pages/PortfolioNew/previewPublicPortfolio')
)
const UserPortfolioProj = React.lazy(() =>
  import('../pages/StartupProfile/userProjects')
)
const MySparkWidgetDetails = React.lazy(() =>
  import('../pages/MySpark/Widgets/WidgetDetails')
)
const MySparkGeneratePage = React.lazy(() =>
  import('../pages/MySpark/GeneratedResponsePage/GeneratedResponsePage')
)
const MySparkArchivePage = React.lazy(() =>
  import('../pages/MySpark/ArchivePage/ArchivePage')
)
const PreviewPortfolioNew = React.lazy(() =>
  import('../pages/PortfolioNew/previewPortfolio')
)
const EditPortfolioNew = React.lazy(() =>
  import('../pages/PortfolioNew/editPortfolio')
)
const PasswordChangeRequired = React.lazy(() =>
  import('../pages/Auth/Login/passwordChangeRequired')
)
const Portfolio2024 = React.lazy(() => import('../pages/Portfolio2024/index'))
const PublicPortfolio2024 = React.lazy(() =>
  import('../pages/Portfolio2024/publicPortfolio')
)
const PeerPortfolio2024 = React.lazy(() =>
  import('../pages/Portfolio2024/peerPortfolio')
)

export const adminRoutes = [
  // { path: '/instructor-data/:id?', component: InstructorData },
  // { path: '/user-management', component: UserManagement }
]

export const authRoutes = [
  { path: '/dashboard', component: Dashboard },
  {
    path: '/create-occupation/:occupationGroupId',
    component: AddOccupationItemForm
  },
  {
    path: '/my-course-in-entrepreneurship',
    component: MyCourseEntrepreneurship,
    exact: true
  },
  { path: '/beyond-your-course', component: BeyondYourCourse, exact: true },
  { path: '/beyond-your-course/:id', component: BeyondYourCourse, exact: true },
  { path: '/my-immersion', component: MyImmersion, exact: true },
  { path: '/my-immersion/:step', component: Steps },
  { path: '/terms', component: Terms },
  // { path: '/preview-portfolio', component: PreviewPortfolioNew },
  { path: '/my-portfolio', component: Portfolio2024, exact: true },
  {
    path: '/peer-portfolio/:username',
    component: PeerPortfolio2024,
    exact: true
  },
  {
    path: '/public-portfolio/:username',
    component: PeerPortfolio2024,
    exact: true
  },
  { path: '/archived-portfolio', component: EditPortfolioNew, exact: true },
  { path: '/portfolio', component: Portfolio },
  { path: '/csv-upload', component: CSVUpload },
  { path: '/savedMedia', component: SavedMedia },
  { path: '/story-in-motion', component: StoryInMotion },
  { path: '/UserProject/:uid', component: UserPortfolioProj },
  { path: '/:page/videos', component: AllVideos },
  { path: '/spotlight', component: Spotlight, exact: true },
  { path: '/startup-livestream', component: LiveStream, exact: true },
  { path: '/startup-live', component: StartupLive, exact: true },
  { path: '/account', component: Profile, exact: true },
  { path: '/profile-preview', component: ProfilePreview, exact: true },
  { path: '/my-journal/:month/:id', component: MyJournals, exact: true },
  { path: '/my-account', component: Profile, exact: true },
  { path: '/verify', component: VerifyEmail },
  { path: '/logout', component: Logout },
  { path: '/iamr', component: IAMR, exact: true },
  { path: '/iamr/:certificationType?/:id?/:type?', component: IAMR },
  { path: '/my-spark/archive', component: MySparkArchivePage, exact: true },
  { path: '/my-spark/widgets', component: MySpark, exact: true },
  { path: '/my-spark/widgets/:widgetName', component: MySparkWidgetDetails },
  { path: '/my-notes/:id?', component: MyNotes, exact: true },
  { path: '/sample-note', component: SampleNote },
  { path: '/:page/video/:id', component: BeyondYourCourseVideo },
  { path: '/my-classroom', component: MyClassroom, exact: true },
  { path: '/my-classroom/request/:id', component: MyClassroom },
  {
    path: '/my-spark/generate-page/:id',
    component: MySparkGeneratePage,
    exact: true
  },
  {
    path: '/my-spark/generate-page/response',
    component: MySparkGeneratePage,
    exact: true
  },
  {
    path: '/My-Market-Ready-Guide',
    component: MyMarketReadyGuide,
    exact: true
  },
  {
    path: '/edit-portfolio/recommendation/:id',
    component: EditPortfolioNew,
    exact: true
  }
]

export const authRoutesWithProps = [
  { path: '/pathways', component: Pathways, exact: true, props: {} },
  { path: '/hs4-journal/', component: LtsJournal, props: { category: 'hs4' } },
  { path: '/hs3-journal/', component: LtsJournal, props: { category: 'hs3' } },
  { path: '/hs2-journal/', component: LtsJournal, props: { category: 'hs2' } },
  { path: '/hs1-journal/', component: LtsJournal, props: { category: 'hs1' } },
  {
    path: '/market-ready-journal',
    component: LtsJournal,
    props: { category: 'market-ready' }
  },
  {
    path: '/my-leadership-journal',
    component: LtsJournal,
    props: { category: 'student-leadership' }
  },
  {
    path: '/my-mentorship',
    component: LtsJournal,
    props: { category: 'my-mentorship' }
  },
  {
    path: '/lts-journal',
    component: LtsJournal,
    props: { category: 'student-lts' }
  },
  {
    path: '/wellness-journal',
    component: LtsJournal,
    props: { category: 'student-wellnes' }
  },
  {
    path: '/student-personal-finance',
    component: LtsJournal,
    props: { category: 'student-personal-finance' }
  },
  {
    path: '/my-course-in-entrepreneurship/journal',
    component: LtsJournal,
    props: { category: 'entrepreneurship' }
  },
  // {
  //   path: '/user-portfolio/:username',
  //   component: PreviewPortfolioNew,
  //   exact: true,
  //   props: { isPublicView: false }
  // },
  {
    path: '/pathways/:occupationId?/:occupationJobId?',
    component: Pathways
  },
  {
    path: '/market-ready/',
    component: LtsJournal,
    props: { category: 'market-ready' }
  },
  {
    path: '/student-leadership/',
    component: LtsJournal,
    props: { category: 'student-leadership' }
  },
  {
    path: '/student-personal-finance/',
    component: LtsJournal,
    props: { category: 'student-personal-finance' }
  },
  {
    path: '/student-wellnes/',
    component: LtsJournal,
    props: { category: 'student-wellnes' }
  },
  {
    path: '/student-lts/',
    component: LtsJournal,
    props: { category: 'student-lts' }
  }
]

export const highSchoolRoutes = [
  {
    path: '/my-immersion',
    component: MyImmersion,
    exact: true,
    isPublic: true
  },
  { path: '/my-immersion/:step', component: Steps }
]

export const publicRoutes = [
  { path: '/verify-email', component: VerifyEmailByCode, exact: false },
  { path: '/verify', component: VerifyEmail, exact: false },
  { path: '/lts-secure', component: SecurePage, exact: true },
  { path: '/forgot-password', component: ForgotPassword, exact: true },
  { path: '/reset-password', component: ResetPassword, exact: true },
  { path: '/create-account', component: CreateAccount, exact: true },
  { path: '/terms', component: Terms, exact: true },
  { path: '/my-immersion', component: MyImmersion, exact: false },
  { path: '/verify', component: VerifyEmail, exact: false },
  { path: '/main-login', component: Login, exact: false },
  { path: '/', component: ChooseLogin, exact: true },
  { path: '/logout', component: Logout, exact: false },
  { path: '/register', component: Register, exact: true },
  { path: '/trial-ended', component: Resubscribe, exact: true },
  { path: '/subscription-ended', component: Resubscribe, exact: true },
  {
    path: '/public-portfolio/:username',
    component: PublicPortfolio2024,
    exact: true
  },

  {
    path: '/password-change-required',
    component: PasswordChangeRequired,
    exact: false
  }
  // {
  //   path: '/user-portfolio/:username',
  //   component: PreviewPublicPortfolio,
  //   props: { isPublicView: true },
  //   exact: true
  // }
]

export const redirects = [
  { from: '/register', to: '/dashboard' },
  { from: '/main-login', to: '/dashboard' },
  { from: '/', to: '/dashboard', exact: true }
]
