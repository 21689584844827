import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { setAccordionToggled } from '../../redux'
import IntlMessages from '../../utils/IntlMessages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faBookmark,
  faCertificate,
  faIdCard,
  faMapSigns
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import $ from 'jquery'

function DefaultSidebar(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  useEffect(() => {
    $(document).on('click', '.dropdownMenuSidebarHover', function () {
      $('.dropdownMenuSidebarHover').each(function () {
        const id = $(this).attr('href')

        if ($(id).hasClass('show') && $(id).find('.active').length < 1) {
          $(this)[0].click()
        }
      })
    })
  }, [])

  useEffect(() => {
    const path = location.pathname.split('/')[1]
    $('.dropdownMenuSidebarHover').each(function () {
      const id = $(this).attr('href')

      if ($(id).hasClass('show') && !$(id).html().includes(path)) {
        $(this)[0].click()
      }
    })
  }, [location])
  return (
    <div>
      <ul
        className='list-unstyled components sidebar-menu-item'
        id='side-menu-main'
      >
        <li>
          <div
            className='accordion accordion-flush'
            id='accordionFlushExample'
          ></div>
          <NavLink
            onClick={() => {
              dispatch(setAccordionToggled(false))
              props.hideHeaderIcons()
            }}
            to='/dashboard'
            className={`${
              location.pathname.includes('dashboard') ? 'active' : ''
            } sidebar-dashboard-li`}
          >
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <div className='dashboard me-1'></div>
              <div className='ms-2'>
                <IntlMessages id='navigation.dashboard' />
              </div>
            </div>
          </NavLink>
        </li>
        {/* <li>
                <NavLink
                  onClick={() => {
                    dispatch(setAccordionToggled(false))
                    props.hideHeaderIcons()
                  }}
                  to="/startup-live"
                  className={`${
                    location.pathname.includes('startup-live') ? 'active' : ''
                  }`}
                >
                  <div className="d-flex" style={{ alignItems: 'center' }}>
                    <FontAwesomeIcon
                      className="sidebar-icon me-1"
                      icon={faTv}
                    />
                    <div className="ms-2">
                      <IntlMessages id="navigation.startup_live" />
                    </div>
                  </div>
                </NavLink>
              </li> */}

        <li
          className='dropdownMenuSidebarHover'
          data-bs-toggle='collapse'
          href='#learn-to-start-collapse'
          role='button'
          aria-expanded='true'
          aria-controls='learn-to-start-collapse'
        >
          <a>
            <div className='d-flex w-100' style={{ alignItems: 'center' }}>
              <FontAwesomeIcon
                className='sidebar-icon me-2'
                icon={faBookmark}
              />
              <div className='ms-1 flex-grow-1'>
                <IntlMessages id='navigation.learn_to_start' />
              </div>
              <FontAwesomeIcon
                icon={faAngleDown}
                className='me-2 me-md-0'
                style={{
                  fontSize: '16px',
                  color: '#333D3D'
                }}
              />
            </div>
          </a>
        </li>
        <div
          className='collapse'
          id='learn-to-start-collapse'
          data-parent='#side-menu-main'
        >
          <li>
            <NavLink
              onClick={() => {
                dispatch(setAccordionToggled(false))
                props.hideHeaderIcons()
              }}
              to='/lts-journal'
              activeClassName='sidenav active'
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div className='ms-4 ps-2 py-1'>
                  <IntlMessages id='navigation.lts_journal' />
                </div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                dispatch(setAccordionToggled(false))
                props.hideHeaderIcons()
              }}
              to='/wellness-journal'
              activeClassName='sidenav active'
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div className='ms-4 ps-2 py-1'>
                  <IntlMessages id='navigation.wellness_journal' />
                </div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                dispatch(setAccordionToggled(false))
                props.hideHeaderIcons()
              }}
              to='/student-personal-finance'
              activeClassName='sidenav active'
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div className='ms-4 ps-2 py-1'>
                  <IntlMessages id='navigation.personal_finance_journal' />
                </div>
              </div>
            </NavLink>
          </li>
        </div>

        <li
          className='dropdownMenuSidebarHover'
          data-bs-toggle='collapse'
          href='#collapseExample'
          role='button'
          aria-expanded='true'
          aria-controls='collapseExample'
        >
          <a>
            <div className='d-flex w-100' style={{ alignItems: 'center' }}>
              <FontAwesomeIcon
                className='sidebar-icon me-2'
                icon={faMapSigns}
              />
              <div className='ms-1 flex-grow-1'>
                <IntlMessages id='navigation.my_market_resources' />
              </div>
              <FontAwesomeIcon
                icon={faAngleDown}
                className='me-2 me-md-0'
                style={{
                  fontSize: '16px',
                  color: '#333D3D'
                }}
              />
            </div>
          </a>
        </li>
        <div
          className='collapse'
          id='collapseExample'
          data-parent='#side-menu-main'
        >
          <li>
            <NavLink
              onClick={() => {
                dispatch(setAccordionToggled(false))
                props.hideHeaderIcons()
              }}
              to='/beyond-your-course'
              activeClassName='sidenav active'
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div className='ms-4 ps-2 py-1'>
                  <IntlMessages id='beyond_your_course.master_classes_upper' />
                </div>
              </div>
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => {
                dispatch(setAccordionToggled(false))
                props.hideHeaderIcons()
              }}
              to='/story-in-motion'
              activeClassName='sidenav active'
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div className='ms-4 ps-2 py-1'>
                  <IntlMessages id='my_saved.STORY_IN_MOTION' />
                  {/*<IntlMessages id="my_saved.MEDIA_PODCASTS" />*/}
                </div>
              </div>
            </NavLink>
          </li>
          {/* <li>
                  <NavLink
                    onClick={() => {
                      dispatch(setAccordionToggled(false))
                      props.hideHeaderIcons()
                    }}
                    to={
                      '/my-course-in-entrepreneurship' +
                      (localStorage.getItem('direct-course-entrepreneurship')
                        ? '/journal'
                        : '')
                    }
                    activeClassName="sidenav active"
                  >
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                      <div className="ms-4 ps-2 py-1">
                        <IntlMessages id="navigation.lts_course_in_entrepreneurship" />
                      </div>
                    </div>
                  </NavLink>
                </li> */}
        </div>
        <li
          className='dropdownMenuSidebarHover'
          data-bs-toggle='collapse'
          href='#collapseCertificates'
          role='button'
          aria-expanded='true'
          aria-controls='collapseCertificates'
        >
          <a>
            <div className='d-flex w-100' style={{ alignItems: 'center' }}>
              <FontAwesomeIcon
                className='sidebar-icon me-2'
                icon={faCertificate}
              />
              <div className='flex-grow-1'>
                <span>MY CERTIFICATION</span>
              </div>
              <FontAwesomeIcon
                icon={faAngleDown}
                className='me-2 me-md-0'
                style={{
                  fontSize: '16px',
                  color: '#333D3D'
                }}
              />
            </div>
          </a>
        </li>
        <div
          className='collapse'
          id='collapseCertificates'
          data-parent='#side-menu-main'
        >
          <li>
            <NavLink
              to={'/My-Market-Ready-Guide'}
              activeClassName='sidenav active'
            >
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div className='ms-4 ps-2 py-1 text-uppercase'>
                  MY MARKET-READY GUIDE
                </div>
              </div>
            </NavLink>
          </li>

          {props.isStudent && (
            <li>
              <NavLink to={'/iamr'} activeClassName='sidenav active'>
                <div className='d-flex' style={{ alignItems: 'center' }}>
                  <div className='ms-4 ps-2 py-1 text-uppercase'>
                    CERTIFICATION SYSTEM
                  </div>
                </div>
              </NavLink>
            </li>
          )}

          {/* <li>
                  <NavLink
                    onClick={() => {
                      dispatch(setAccordionToggled(false))
                      props.hideHeaderIcons()
                    }}
                    to="/market-ready-journal"
                    activeClassName="sidenav active"
                  >
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                      <div className="ms-4 ps-2 py-1">
                        <IntlMessages id="navigation.market_ready" />
                      </div>
                    </div>
                  </NavLink>
                </li> */}
        </div>
        <li>
          <NavLink
            onClick={() => {
              dispatch(setAccordionToggled(false))
              props.hideHeaderIcons()
            }}
            to={'/my-portfolio'}
            className='mt-md-1'
            activeClassName='sidenav active'
          >
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <FontAwesomeIcon icon={faIdCard} />
              <span className='ps-2'>MY PORTFOLIO</span>
            </div>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}

export default DefaultSidebar
